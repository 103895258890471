<template>
  <div>
    <div class="input-upload" v-if="!image && (inputData === '' || inputData === undefined)">
      <input
        type="file"
        ref="files"
        v-if="isImage"
        :name="name"
        class="input-hidden"
        v-validate="{ required: required, size: size ? size : 1000, mimes: ['image/jpeg' ,'image/png']}"
        @change="onFileChange"
        :required="required"
      >
      <input
        type="file"
        ref="files"
        v-if="isFile"
        :name="name"
        class="input-hidden"
        v-validate="{ required: required, size: size ? size : 1000, mimes: 'application/pdf'}"
        @change="onFileChange"
        :required="required"
      >
      <div>
        <p class="name-upload">
          Drag your files here
          <br>or
          <br>click in this area
        </p>
      </div>
    </div>
    <div v-else>
      <img v-if="isImage" :src="image" width="100px">
      <span v-if="isFile && image === ''">
        <i class="mdi mdi-file"/>
      </span>
      <span v-if="isFile && image != ''">{{image}}</span>
      <button type="button" class="btn btn-sm btn-default" v-on:click="removeImage">Remove</button>
    </div>
    <small v-if="errors.has(name)" class="error-message">This field has error.</small>
  </div>
</template>
<script>
  /* eslint-disable no-unused-vars */
export default {
  props: {
    name: {
      required: true,
      type: String
    },
    isFile: {
      required: false,
      type: Boolean
    },
    isImage: {
      required: false,
      type: Boolean
    },
    required: {
      type: Boolean,
      required: false
    },
    size: {
      type: Number,
      required: false
    },
    value: {
      required: false
    }
  },
  data() {
    return {
      image: "",
      file: ""
    };
  },
  created() {
    if (this.value !== "" || this.value !== undefined) {
      if (this.isImage) {
        this.base64toImage(this.value);
      }
      if (this.isFile) {
        this.base64toFile(this.value);
      }
    }
  },
  computed: {
    inputData() {
      return this.value;
    }
  },
  methods: {
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      this.$validator.validate().then(result => {
        if (result) {
          if (this.isImage) {
            this.createImage(files[0]);
          }
          if (this.isFile) {
            this.createFile(files[0]);
          }
        }
      });
    },
    createImage(file) {
      var image = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.onload = e => {
        vm.image = e.target.result;
        vm.file = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    createFile(file) {
      var reader = new FileReader();
      var vm = this;

      vm.image = file.name;

      reader.onload = e => {
        vm.file = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    base64toImage(base64) {
      this.image = base64;
    },
    base64toFile(base64) {
      this.image = "";
    },
    removeImage: function() {
      this.image = "";
      this.file = "";
      this.$emit("input", "");
    }
  },
  watch: {
    file(val) {
      this.$emit("input", val);
    }
  }
};
</script>
<style>
.input-upload {
  width: 150px;
  height: 50px;
  cursor: pointer;
  border: 2px dashed #301d69ed;
}
.name-upload {
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 16px;
  font-size: 12px;
  color: #8e8585;
  font-family: Arial;
}
.name-upload-more {
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 45px;
  font-size: 12px;
  color: #8e8585;
  font-family: Arial;
}
.input-hidden {
  position: absolute;
  margin: 0;
  padding: 0;
  cursor: pointer;
  width: 150px !important;
  height: 50px !important;
  outline: none;
  opacity: 0;
}
.error-message {
  color: #f62d51;
}
</style>
