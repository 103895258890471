<template>
  <div :class="['form-group', {'has-danger': errors.has(name)},' row']">
    <label
      for="error"
      v-if="label"
      :class="['control-label', {'text-right': !isMobile}, 'col-md-3']"
    >
      {{label}}
      <small v-if="required" style="color:red">*</small>
    </label>
    <div :class="label ? 'col-md-9 col-xs-9': 'col-md-12 col-xs-12'">
      <input
        :type="type"
        v-validate="required ? 'required': ''"
        :name="name"
        :class="['form-control', label ? '': {'form-control-danger': errors.has(name)}]"
        :placeholder="placeholder"
        :min="min"
        oninput="validity.valid||(value='')"
        @input="updateValue($event.target.value)"
        :value="value"
        :disabled="disabled"
      >
      <small v-if="errors.has(name) " class="form-control-feedback">This field is required.</small>
    </div>
  </div>
</template>
<script>
const Input = {
  props: {
    label: {
      type: String,
      required: false
    },
    type: {
      required: true,
      type: String
    },
    name: {
      required: true,
      type: String
    },
    placeholder: {
      required: false,
      type: String
    },
    required: {
      type: Boolean,
      required: false
    },
    value: {
      required: false
    },
    disabled: {
      type: Boolean,
      required: false
    },
    min: {
      type: Number,
      required: false
    }
  },
  methods: {
    updateValue(val) {
      this.$emit("input", val);
    }
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    }
  },
  inject: ["$validator"]
};
export default Input;
</script>
<style scoped lang="scss">
.form-control {
  min-height: 42px !important;
}
.form-control-danger {
  background-image: linear-gradient(#f62d51, #f62d51),
    linear-gradient(#d9d9d9, #d9d9d9) !important;
}
</style>
